<template>
    <div class="single-image"
        :class="content.isHorizontalMode ? 'row' : 'column'"
        :style="{order : content.order}">
        <image-details v-for="(image, index) in content.images" :key="index"
        :imageDetails="image"/> 
    </div>
</template>

<script>
import ParagraphMixin from './ParagraphMixin.js'

export default {
  name: "OnlyImages",
  mixins:[ParagraphMixin],
}
</script>

<style lang="less" scoped>

.single-image{
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    .image-details-container {
        margin-right: 16px;
    }
}

</style>
