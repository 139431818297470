<template>
    <div class="paragraph row" :style="{order : content.order}">
        <section class="column" :class="content.imagesOrientation">
            <image-details v-for="(image, index) in images" 
                            :key="index" :imageDetails="image"
                            :default-width="imageDetailsWidth"/>
        </section>
        <div class="column" :style="{'justify-content': content.alignment}">
             <essential-question  v-if="essentialQuestionText" :key="content.essentialQuestion.id"
                :audio="essentialQuestionAudio"    :message="essentialQuestionText" />
            <div class="body">
                <div>
                    <span  @click="GoToLink" v-html="textContent" ></span>
                </div> 
                <AudioToggle v-if="content.audio" :audio="content.audio"/>
            </div>
        </div>

        <modal v-if="showModal" :text-button="$t('done')"
            :click-handler="hideVocabularyBox" 
            :clickOutSideModalHandler="hideVocabularyBox"
            :showCancelButton="showModalCancelButton"
            :modalType="modalType"> <vocabulary-box :vocabulary="selectedWord" /></modal>
    </div>
</template>

<script>
import ParagraphMixin from './ParagraphMixin.js'

export default {
  name: 'LessonParagraph',
  mixins:[ParagraphMixin],
  computed:{
      imageDetailsWidth(){
          return this.content.imagesOrientation === "right-side" ? 700 : 1280; 
      }
  }
}
</script>

<style lang="less">
.lesson{
    .paragraph{
        .column{
            max-width: 700px;
        }
        .right-side{
            margin-right: auto;
            margin-left: auto;  
        }
        .left-side{
            margin-left: auto;
        }
    }
}
</style>