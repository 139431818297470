<template>
    <div class="interactive-map" :class="{'horizontal-mode' : content.isHorizontalMode}"
     v-if="mapBackground" :style="{order : content.order}" >
      <div class="image-container row">
        <img :src="mapBackground" class="bg" v-on:load="getImageWidth">
        <img :src="overlay" class="centered overlay">
        <img v-for="image in routeImages" class="centered"
                 :key="image" :src="getImageUrl(image)">
      </div>
      <div class="row buttons">
        <button class="btn round secondary" v-if="currentPointIndex >= 0"
         @click="previousPoint">{{$t('back')}}</button>
        <button class="btn round primary" v-if="currentPointIndex <  this.points.length-1"
           @click="nextPoint">{{$t('next')}}</button>
      </div>
      <point v-if="currentPoint" :content="currentPoint"
        :display-number="content.displayStepNumbers" :key="currentPoint.id" />
      <template v-else>
        <div class="map-point body">
          <span class="legend">{{$t('clickNextMapLegend')}}</span>
        </div>
      </template>
    </div>
</template>

<script>
import ParagraphMixin from './ParagraphMixin.js'
import MapPoint from './MapPoint.vue'
export default {
  name: 'InteractiveMap',
  mixins:[ParagraphMixin],
  components:{
    'point': MapPoint
  },
  data(){
    return{
      currentPointIndex: -1, 
      routeImages: ''
    }
  },
  computed:{
    points(){
      return this.content.routePoints   
    },
    currentPoint(){
      return this.points[this.currentPointIndex]
    }, 
    mapBackground(){
      let backgroundImageName;
        this.content.images[0] 
          ? backgroundImageName = this.content.images[0].name 
          : backgroundImageName = null
        return this.getImageUrl(backgroundImageName)
    },
    overlay(){
      if(!this.content.images[1])
        return 
      let backgroundImage = this.content.images[1].name 
      return this.getImageUrl(backgroundImage)
    }
  }, 
  methods:{
    nextPoint(){
      if(this.currentPointIndex >=  this.points.length-1)
        return
      this.currentPointIndex++
    }, 
    previousPoint(){
      if(this.currentPointIndex == -1)
        return
      this.currentPointIndex--
    }
  }, 
  watch:{
    currentPointIndex(value){
      let images =[]
      if(value < 0){
        this.routeImages =[]
        return;
      }
      !this.content.isProgressiveMap 
        ? images = this.points[value].images
        : this.points.forEach((e, index) =>{
            if(index <= value)
            e.images.forEach(i => images.push(i))
          })

      this.routeImages =images
    }
  }
}
</script>

<style lang="less">
.lesson .interactive-map{
  width: 1280px;
  margin: 20px auto;
  flex-direction: column;
  button{
     &:focus{
        outline: none;
    }
  }
  .image-container{
      position: relative;
      max-height: 514px;
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      img:first-child{
          margin: auto;
      }
      img{
          max-height: 514px;
          max-width: 1280px;
      }
  }
  .buttons{
      position: relative;
      padding: 8px;
      .primary{
          margin-left: auto;
      }
  }
  .overlay{
    z-index: 200;
  } 

  .legend{
    text-align: center;
    font-size: 24px;
    height: auto;
    margin: auto;
    color: #0A1554;
  }

  &.horizontal-mode{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .image-container{
      max-width: 700px;
      margin: unset;
      margin-left: auto;
      order: 0;
    }
    img{
      max-width: 700px;
    }
    .map-point{
      order:1;
      max-width: 550px;
      margin: unset;
      margin-right: auto;
      min-height: 100%;
      p{
        max-width: 440px;
        min-width: 440px;
      }
    }
    .buttons{
      order: 2;
      min-width: 1280px;
    }
    .legend{
      max-width: 380px;
      white-space: normal;
    }

  }

}


</style>
