<template>
    <div class="image-details-container column" 
        :style="{ 'max-width': `${captionWidth}px`, 'margin-bottom': `${imageDetails.marginBottom}px` }">
        <span class="title" v-if="title" :style="{ width: `${captionWidth}px` }">{{title}}</span>
        <div class="image-details-image" :class="[{ 'shadowed-image': imageDetails.shadow }]">
            <img :src="imageUrl" 
                :class="{'drop-shadow': imageDetails.shadow}"
                :style="{ 'max-width': `${maxWidth}px` }" 
                v-on:load="onLoadImage">
            <audio-toggle v-if="imageDetails.audio" :white-background="true" :audio="imageDetails.audio"/>
        </div>
        <span class="disclaimer" 
            v-if="disclaimer" 
            :style="{ 'margin-left': `${captionMargin}px`, width: `${captionWidth}px` }">{{disclaimer}}</span>
        <div class="image-details-caption row" v-if="caption" :style="{ width: `${captionWidth}px` }">
            <audio-toggle v-if="captionAudioName" 
                        :style="{ 'margin-left': `${captionMargin}px` }"
                        :white-background="true"
                        :audio="imageDetails.captionAudio"/>
            <div :style="captionStyles" v-html="caption"/>
        </div>
    </div>
</template>

<script>
import AudioToggle from "@/common/AudioToggle.vue";

export default {
  name: "ImageDetails",
  components:{
      AudioToggle
  },
  props:{
      imageDetails: Object,
      defaultWidth: {
          type: Number,
          required: false,
          default: 1280
      }
  },
  data(){
      return {
          captionWidth: this.maxWidth
      }
  },
    computed:{
        title(){
            return this.imageDetails.title;
        },
        disclaimer(){
            return this.imageDetails.disclaimer;
        },
        captionAudioName(){
            return this.imageDetails.captionAudio ? 
                        this.locale === "en" ? 
                            this.imageDetails.captionAudio.nameEnglish
                            : this.imageDetails.captionAudio.nameSpanish
                        : null;
        },
        caption(){
            return this.imageDetails.caption ? 
                    this.imageDetails.caption[this.locale]
                    : null;
        },
        captionMargin(){
            return this.imageDetails.captionMargin != "" ? this.imageDetails.captionMargin : 0;
        },
        imageUrl(){
            return this.getImageUrl(this.imageDetails.name);
        },
        captionStyles(){
            return { ...this.captionAudioName == null && { "margin-left": `${this.captionMargin}px`} }
        }
    },
  methods:{
      onLoadImage({ path }){
        let _imgElement = path[0];
        let _width = 300;

        if(_imgElement.width >= this.maxWidth)
            _width = this.maxWidth;
        else if(_imgElement.width > _width)
            _width = _imgElement.width;

        this.captionWidth = _width;
      }
  }, 
  created(){
      this.maxWidth = this.defaultWidth
  }
}
</script>

<style lang="less" scoped>

.image-details-container{
    span{
        letter-spacing: 0.25px;
        color: #0A1554;

        &.title{
            text-align: center;
            text-transform: uppercase;

            font-size: 24px;
            font-weight: bold;
            line-height: 24px;

            margin-bottom: 16px;
        }

        &.disclaimer{
            color: #0A1554;
            font-family: "Roboto";
            font-size: 12px;
            font-style: italic;
            letter-spacing: 0.4px;
            line-height: 16px;

            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .image-details-image{
        margin: auto;
        height: auto;
        position: relative;

        &.shadowed-image{
            margin-bottom: 10px;
        }

        .audio-toggle{
            position: absolute;
            right: 0;
            bottom: 5px;
        }
    }

    .image-details-caption{
        margin-top: 5px;

        .audio-toggle{
            margin-right: 10px;
        }

        & /deep/ p{
            margin: 0px;
        }
    }
}

</style>
