<template>
    <div class="map-point">
        <div class="body">
            <span  :style="{opacity: displayNumber ? 1: 0}">{{content.number}}</span>
            <p class="map-paragraph"  @click="GoToLink" v-html="textContent" ></p>
            <AudioToggle v-if="content.audio" :audio="content.audio"/>
        </div>
        <modal v-if="showModal" :text-button="$t('done')" 
        :showCancelButton="showModalCancelButton"
        :click-handler="hideVocabularyBox" @close="showModal = false">
            <vocabulary-box :vocabulary="selectedWord" />
        </modal>
    </div>
</template>
<script>
import ParagraphMixin from './ParagraphMixin.js'
export default {
  name: 'MapPoint',
  mixins:[ParagraphMixin],
  props:{
      displayNumber:{
          type: Boolean, 
          required: false, 
          default: true
      }
  }
}
</script>
<style lang="less">
.map-point{
    min-height: 136px;
    width: 960px;
    max-width: 960px;
    transition: all .5s ease;
    margin: auto;
    margin-top: -50px;
    .body{
        span{
            transition: all .5s ease;
            height: 24px;
            min-width: 30px;
            color: #3A4DFD;
            font-size: 19.64px;
            font-weight: bold;
            margin-left: 24px;
            margin-top: 24px;
            font-family: 'Montserrat', sans-serif;
        }
        p{
            transition: all .5s ease;
            max-width: 850px;
            min-width: 850px;
            color: #0A1554;
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 0.25px;
            line-height: 24px;
            white-space: pre-line;
        }
        .audio-toggle{
            margin-top: 8px !important;
            margin-left: 24px;
        }
    }
}
</style>
