import { render, staticRenderFns } from "./OnlyImages.vue?vue&type=template&id=020238be&scoped=true&"
import script from "./OnlyImages.vue?vue&type=script&lang=js&"
export * from "./OnlyImages.vue?vue&type=script&lang=js&"
import style0 from "./OnlyImages.vue?vue&type=style&index=0&id=020238be&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "020238be",
  null
  
)

component.options.__file = "OnlyImages.vue"
export default component.exports