<template>
    <div class="card row" :style="{order : content.order}">
        <section class="column" :class="content.imagesOrientation">
            <image-details v-for="(image, index) in images" 
                            :key="index" :imageDetails="image"
                            :default-width="304"/>
        </section>
        <div class="column " :style="{'align-self': content.alignment}">
            <div class="card-header row">
                <span class="card-title" >{{content.title}}</span>
                <AudioToggle v-if="content.audio"
                :white-background="true"
                 :audio="content.audio"/>
            </div>
            <div class="body">
                <div>
                 <span  @click="GoToLink" v-html="textContent" ></span>
                </div> 
            </div>
        </div>
        <modal v-if="showModal" :text-button="$t('done')" 
            :click-handler="hideVocabularyBox" 
            :clickOutSideModalHandler="hideVocabularyBox"
            :showCancelButton="showModalCancelButton"
            :modalType="modalType"> <vocabulary-box :vocabulary="selectedWord" /></modal>
    </div>
</template>

<script>
import ParagraphMixin from './ParagraphMixin.js'
export default {
  name: 'LessonCard',
  mixins:[ParagraphMixin],
}
</script>

<style lang="less">

.card{
    img{
        height: auto;
        max-width: 304px;
        border-radius: 10px;
        margin: auto;
    }
    .column:first-child{
        margin-left: auto;
    }
    .column:nth-child(2){
        margin-right: auto;
    }

    .card-title{
        width: 400px;
        white-space: normal;
        height: auto;
        color: #fff;
        font-size: 19.64px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 24px;
        margin: auto;
        padding: 8px;
    }

    .column:nth-child(2) .card-header{
        min-height: 56px;
        width: 624px;
        background-color: #0A1554;
        position: relative;
        align-self: center;
        .audio-toggle{
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translate(0, -50%);
        }
    }
    p{
        font-size: 16px !important;
        letter-spacing: 0.5px !important;
        line-height: 24px !important;
    }

    .body{
        width: 624px;
        max-width: 624px;
    }
}

</style>