<template>
    <div class="text-content" :style="{order : content.order}" >
        <essential-question  :style="{width: width + 'px'}" :class="[content.orientation]" v-if="essentialQuestionText" :key="content.essentialQuestion.id"
                :audio="content.essentialQuestion.audio"    :message="essentialQuestionText" />
        <div class="body" :style="{width: width + 'px'}" :class="[content.orientation]">
            <div>
                <span  @click="GoToLink" v-html="textContent" ></span>
            </div> 
            <AudioToggle v-if="content.audio" :audio="content.audio"/>
        </div>
        <modal v-if="showModal" :text-button="$t('done')" 
        :showCancelButton="showModalCancelButton"
        :click-handler="hideVocabularyBox" @close="showModal = false">
            <vocabulary-box :vocabulary="selectedWord" />
        </modal>
    </div>
</template>

<script>
import ParagraphMixin from './ParagraphMixin.js'
export default {
  name: 'LessonTextContent',
  mixins:[ParagraphMixin],
  computed:{
    width(){
        return  ((1280/12)* this.content.size)
    }, 
  }
}
</script>

<style lang="less">
    .text-content{
        .body{
            max-width: 1280px !important;
            div{
                max-width: 1280px !important;
            }
        }
    }

</style>