<template>
    <div class="section-title row" :class="[content.orientation]" 
     :style="componentStyles">
        <img v-if="image" :src="background" :style="{maxWidth: width +'px'}">
        <span class="centered" :style="{color: content.color}" >{{content.title}}</span>
    </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props:{
      content: Object
  },
  computed:{
      background(){
          return this.getImageUrl(this.image.name)
      }, 
      width(){
          return  ((1280/12)* this.content.size)
      }, 
      componentStyles(){
          return {
            backgroundColor: !this.hasImage ? this.content.background : '',
            'min-height': this.hasImage ? '145px' : '41px',
            opacity: !this.hasImage ? (this.content.opacity / 100) : 1, 
            width: this.width + 'px', 
            order : this.content.order, 
          }
      },
      image(){
          return this.content.images[0];
      }
  }

}
</script>

<style lang="less">

.lesson{
    .section-title{
        margin-top: 40px;
        min-height: 41px;
        display: flex;
        justify-content: center;
        position: relative;
        background-color: unset;
        z-index: 0;
        span{
            position: absolute;
            margin: auto;
            height: 41px;
            color: #3A4DFD;
            font-family: 'Montserrat', sans-serif;
            font-size: 33.64px;
            font-weight: 600;
            letter-spacing: 0.25px;
            line-height: 41px;
            text-align: center;
            justify-self: center;
        }
        img{
            width: auto;
            height: auto;
            z-index: -1;
            margin: auto;
        }
    }
}

</style>